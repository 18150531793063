html {
	font-family: Georgia, serif;
	font-size: 16pt;
}

@media (min-width: 1900px) {
	html {
		font-size: 24pt;
	}
}

@media (min-width: 3500px) {
	html {
		font-size: 32pt;
	}
}

body {
	margin: 0;
	padding: 0;
}

header {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 16rem;
	display: flex;
	background: #333 url(./header.jpg) no-repeat;
	background-position: 50%;
	background-size: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: white;
	font-style: italic;
	position: relative;
}

header.home-page {
    height: auto;
    background: #333;
    aspect-ratio: 3 / 1;
    position: relative;
}

header.home-page .header-title {
    position:absolute;
    display: inline-block;
    border-radius: 0 0.25rem 0 0;
    background: rgba(51, 51, 51, 0.5);
    left: 0;
    bottom: 0;
    padding: 0.5rem;
    font-size: 1rem;
}

country-map.mapboxgl-map {
    aspect-ratio: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

country-map.mapboxgl-map a.mapboxgl-ctrl-logo {
    display: none;
}

.page, .breadcrumbs > *, footer {
	margin: 0 auto;
	padding: 0 1rem;
	max-width: 33rem;
}

.mapboxgl-map {
    display: block;
    width: 100%;
    aspect-ratio: 1 / 1;
}

footer {
	margin: 1.5rem auto;
}

article {
	margin: 1rem 0;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0.5rem 0;
	font-weight: normal;
	font-style: italic;
}

blockquote {
	font-style: italic;
}

article h1 {
	margin: 0;
	font-size: 2rem;
}

article .meta {
	margin: 0;
	opacity: 0.6;
}

article .meta a {
	color: inherit;
	border-color: #555;
}

.entry-pagination {
	margin: 1rem 0;
	text-align: center;
	position: relative;
}

.entry-pagination > * {
	transition: 0.2s opacity;
	opacity: 1;
}

.entry-pagination > a[data-title]:hover ~ span:not(:hover) {
	opacity: 0;
}


.entry-pagination > a {
	border: none;
}

.entry-pagination > .prev,
.entry-pagination > .next {
	position: absolute;
}

.entry-pagination > .prev {
	left: 0;
}

.entry-pagination > .next {
	right: 0;
}

.entry-pagination > span {
	font-style: italic;
}

.entry-pagination > .prev::after,
.entry-pagination > .next::before {
	transition: opacity 0.3s 0.2s;
	opacity: 0;
	content: "";
}

.entry-pagination > .prev:hover::after,
.entry-pagination > .next:hover::before {
	opacity: 1;
}

.entry-pagination > .prev[data-title]:hover::after {
	content: " – " attr(data-title);
}

.entry-pagination > .next[data-title]:hover::before {
	content: attr(data-title) " – ";
}

a {
	color: #4695db;
	border-bottom: 2px solid #4695db;
	text-decoration: none
}

a > img,
.mapboxgl-ctrl a {
	border: none;
}

p {
	margin: 1.5rem 0;
	line-height: 1.4;
	text-align: justify;
}

p > img {
	max-height: 16pt;
	vertical-align: text-bottom;
}

ul {
	margin: 1.5rem 0;
	line-height: 1.4;
}

.figure {
	margin: 1.5rem 0;
	display: flex;
	overflow: hidden;
	border-radius: 0.25rem;
	flex-direction: column;
	position: relative;
}
a.figure {
	color: inherit;
	border: none;
}
.figure > * {
	max-width: 100%;
}
.figure > .caption {
	padding: 1em;
	background: #dbdbdb;
}

.figure > a.caption {
	color: inherit;
	border: none;
}

.figure > p {
	margin: 1rem;
	font-size: 0.8rem;
}

.figure-note {
	background-color: #ebebeb;
}

.figure > .overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	background-color: transparent;
	transition: background-color 0.1s ease-in;
}
.figure > .overlay:hover {
	background-color: rgba(0, 0, 0, 0.3);
}

.overlay .text {
	color: white;
}
.overlay .text-big {
	font-size: 2rem;
	font-style: italic;
}

.note {
	margin: 1rem 0;
	padding: 1rem;
	background-color: #ccc;
}

.journey-list {
	padding: 0;
	list-style-type: none;
}

.phrases {
	border-collapse: collapse;
	line-height: 1.4;
}
.phrases td:last-child {
	font-style: italic;
}
.phrases th {
	border-bottom: 1px solid #ccc;
}
.phrases td + td {
	padding-left: 1em;
}

.gallery {
	margin: 1.5em 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}
.gallery .figure {
	margin: 0.5rem 0;
	border-bottom: none;
	position: relative;
}
.gallery .figure::after {
	padding: 0.5rem;
	content: attr(data-caption);
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	color: white;
	background: rgba(0, 0, 0, 0.5);
	opacity: 0;
	transition: opacity 0.3s;
}
.gallery .figure:hover::after {
	opacity: 1;
}

.gallery img {
	width: calc(33rem / 2 - 0.5rem);
	height: calc(33rem / 2 - 0.5rem);
	object-fit: cover;
}
#gallery-view {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
}
#gallery-view .caption {
	padding: 1rem;
	background: #dbdbdb;;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.definition-table tr + tr > td {
	padding-top: 0.5rem;
}
.definition-table tr > td {
	vertical-align: top;
}
.definition-table td + td {
	padding-left: 0.5rem;
}

.entry-list {
	padding: 0;
	list-style-type: none;
}
.entry-list > li + li {
	margin-top: 1.5rem;
}
.entry-list > li > a {
	display: flex;
	border-bottom: none;
	color: inherit;
	max-width: 100%;
}
.entry-list > li > a > :first-child {
	border-radius: 0.25rem;
	width: 3.5rem;
	height: 3.5rem;
	object-fit: cover;
	background: #333;
	text-align: center;

}
.entry-list > li > a > :first-child:not(img)::after {
	color: white;
	content: "x";
	line-height: 3rem;
	font-size: 3rem;
	font-style: italic;
	opacity: 0.3;
}
.entry-list > li > a > div {
	padding-left: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	min-width: 0;
}
.entry-list .title {
	font-size: 1.7rem;
	font-style: italic;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow-x: hidden;
	overflow-x: clip; /* Not supported in Safari */
	line-height: 1.2;
}
.entry-list .date {
	font-size: 0.9rem;
	line-height: 1.2;
}

.breadcrumbs {
	padding: 0.25rem 0;
	font-style: italic;
	color: #fff;
}
.breadcrumbs ul {
	padding: 0;
	list-style-type: none;
	display: flex;
}
.breadcrumbs li {
	padding: 0;
	white-space: nowrap;
}
.breadcrumbs li:last-child {
	overflow: hidden;
	text-overflow: ellipsis;
}
.breadcrumbs li:not(:first-child)::before {
	padding: 0 0.5rem;
	content: "»";
	opacity: 0.5;
}
.breadcrumbs a {
	color: inherit;
	border: none;
	opacity: 0.7;
	transition: opacity 0.3s;
}
.breadcrumbs a:hover {
	opacity: 1;
}

header .breadcrumbs {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
}
